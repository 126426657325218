export default function(duration, fps) {
  // 00:10
  const obj = {
    0: 1,
    1: 60,
    2: 3600,
  };
  const time = duration?.split(':');
  let dur = 0;
  let i = 0;
  while (time?.length > 0) {
    dur += Number(time.pop()) * obj[i];
    i++;
  }
  return dur * fps;
}
