<template>
  <a-descriptions
    :title="videoInfo?.fileName"
    size="small"
    id="annotate-video-details"
  >
    <a-descriptions-item label="Type" span="3"
      ><a-tag>
        {{ videoInfo?.fileName.split('.').pop() }}
      </a-tag></a-descriptions-item
    >

    <a-descriptions-item label="Duration" span="3"
      ><a-tag>
        {{ duration }}
      </a-tag></a-descriptions-item
    >
    <a-descriptions-item label="Total Frames" span="3"
      ><a-tag>
        {{ totalFrames }}
      </a-tag></a-descriptions-item
    >

    <a-descriptions-item label="Resolution" span="3"
      ><a-tag>
        {{ videoInfo?.resolution }}
      </a-tag></a-descriptions-item
    >

    <a-descriptions-item label="FPS" span="3"
      ><a-tag>
        {{ fps }}
      </a-tag></a-descriptions-item
    >
  </a-descriptions>
  <a-input
    v-if="!isVisualize && !isTrimming && isLabeler"
    type="number"
    class="w-50 mt-4"
    :value="frameStepSize"
    @change="handleFrameStepSizeChane"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { roles } from 'src/config/roles-config';
export default {
  props: [
    'videoInfo',
    'totalFrames',
    'videoMetaData',
    'isTrimming',
    'isVisualize',
  ],
  computed: {
    ...mapGetters(['frameStepSize', 'role']),
    fps() {
      return this.videoMetaData?.frameRate || this.videoInfo?.fps;
    },
    duration() {
      return this.videoMetaData?.duration || this.videoInfo?.duration;
    },
    isLabeler() {
      return roles.labeler === this.role;
    },
  },
  methods: {
    ...mapActions(['setFrameStepSize']),
    handleFrameStepSizeChane(e) {
      const { value } = e.target;
      this.setFrameStepSize(value);
    },
  },
};
</script>
<style scoped>
.btn {
  border-radius: 18px;
  padding: 5px 18px;
}
.fw-b {
  font-weight: bold;
}
</style>
