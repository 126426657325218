<template>
  <div class="card h-100">
    <div class="card-block h-100 text-center">
      <video
        ref="video"
        :src="videoUrl"
        :key="videoUrl"
        @playing="handlePlay"
        @loadedmetadata="handleLoadedMetaData"
        crossorigin="anonymous"
        muted
        id="annotate-video-player"
      ></video>
      <canvas
        ref="canvas"
        width="640"
        height="480"
        style="display: none"
      ></canvas>
    </div>
  </div>
</template>
<script>
import httpClient from '../../../../service/httpClient';
import { mapGetters } from 'vuex';
import { saveAs } from 'file-saver';

export default {
  props: ['currentFrame', 'videoUrl', 'videoFPS'],
  emits: ['updateTotaleFrame'],
  data() {
    return {
      guessingInterval: null,
      fps: 0,
      oldTime: 0,
      currentSecond: 0,
      count: 0,
      isImageLoaded: false
    };
  },
  watch: {
    currentFrame: function (value) {
      const frameToMove = (Number(value) / this.videoFPS).toFixed(25);
      this.$refs.video.currentTime = frameToMove;
    },
  },
  computed: {
    ...mapGetters(['organization', 'getTaskId']),
  },
  methods: {
    onImageLoad () {
      this.isImageLoaded = true
    },
    createBlobName(taskName, videoName, currentFrame) {
      return taskName + '-' + videoName + '-' + currentFrame + '.jpg';
    },

    uploadFrameForAnnotationTest(taskName, videoName, currentFrame) {
      // let imageName = this.createBlobName(taskName, videoName, currentFrame);
      // var w = 640 * 1.25;
      // var h = 480 * 1.25;
      // this.$refs.canvas.width = w;
      // this.$refs.canvas.height = h;
      // const context = this.$refs.canvas.getContext('2d');
      // context.drawImage(this.$refs.video, 0, 0, w, h);
      // let image = this.$refs.canvas.toDataURL('image/jpeg');
      // console.log(image, this.$refs.video.videoWidth, this.$refs.video.videoHeight)
      // this.$refs.canvas.toDataURL(image => {
      //     saveAs(image, "frame.jpeg");
      //   }, 'image/jpeg');

      this.$refs.canvas.width = 640;
      this.$refs.canvas.height = 480;
      var context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.video, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height); // for drawing the video element on the canvas
        
      /** Code to merge image **/
      
      // const playImage = new Image();
      // playImage.src = 'path to image asset';
      this.$refs.video.onload = () => {
        const startX = (1280 / 2) - (640 / 2);
        const startY = (960 / 2) - (480 / 2);
        context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.video, startX, startY, 640, 480);
        // this.$refs.canvas.getContext('2d').drawImage(this.$refs.video, startX, startY, 640, 480);
        this.$refs.canvas.toBlob( image => {// Canvas element gives a callback to listen to the event after blob is prepared from canvas
          const img = new Image();
          // img.src = window.URL.createObjectUrl(blob); // window object with static function of URL class that can be used to get URL from blob
          console.log(img, img.src, image)
          saveAs(image, "frame.jpeg");
        }, 'image/jpeg');
      };
    },

    async uploadFrameForAnnotationtest1(taskName, videoName, currentFrame) {
      const blob = await new Promise(resolve => {
        let imageName = this.createBlobName(taskName, videoName, currentFrame);
        const context = this.$refs.canvas.getContext('2d');
        console.log(this.$refs.video,"this.$refs.video.onload", this.isImageLoaded)
        
        
        // this.$refs.video.onload = () => context.drawImage(this.$refs.video, 0, 0, 640, 480);
        // this.$refs.canvas.toBlob(
        //   (image) => this.uploadImageOnCloud(image, imageName, taskName),
        //   'image/jpeg'
        // );
        // this.$refs.video.onload = function() {
        // this.$refs.canvas.toBlob(function(image) {
        //   console.log(image.src)
        //   saveAs(image, "frame.png");
        // });
        
        const newImage = new Image();
        this.$refs.video.crossOrigin = 'anonymous';
        // context.drawImage(
        //   this.$refs.video, 0, 0, 640, 480
        // );
        this.$refs.video.onload = () => {
          context.drawImage(
            this.$refs.video, 0, 0, 640, 480
          );
          // this.$refs.canvas.toBlob(image => {
          //   saveAs(image, "frame.jpeg");
          // }, 'image/jpeg'); 
        };
        this.$refs.canvas.toBlob(image => {
          saveAs(image, "frame.jpg");
          resolve(image)
        }, 'image/jpg');        
        
      // newImage.src = image.src;
      });
      // window.URL.revokeObjectURL(this.$refs.video)
    },

    uploadFrameForAnnotation(taskName, videoName, currentFrame) {
      let imageName = this.createBlobName(taskName, videoName, currentFrame);
      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.video, 0, 0, 640, 480);
      this.$refs.canvas.toBlob(
        (image) => this.uploadImageOnCloud(image, imageName, taskName),
        'image/jpeg'
      );
    },
    
    async uploadImageOnCloud(image, imageName, taskName) {
      // image.setAttribute('crossOrigin', 'anonymous');
      const data = this.createImageFormData(image, imageName, taskName);
      const response = await this.uploadFile(data);
      if (response !== 'error') {
        const isRecordCreated = await this.uploadNewImageRecord(
          this.createImageRecordData(taskName, imageName)
        );
      }
    },
    async uploadFile(data) {
      return await httpClient.post(
        'generic/file_upload/',
        data,
        false,
        false,
        false
      );
    },
    createImageRecordData(taskName, imageName) {
      const imagePathInBucket = `${taskName}/Detector/images/${imageName}`;
      return {
        task: this.getTaskId,
        path_to_image: imagePathInBucket,
        path_to_annotations: null,
      };
    },
    createImageFormData(image, imageName, taskName) {
      const data = new FormData();
      const imagePathInBucket = `${taskName}/Detector/images/${imageName}`;
      data.append('file', image, imageName);
      data.append('file_path', imagePathInBucket);
      data.append('bucket', this.organization + '-training');
      return data;
    },

    async uploadNewImageRecord(data) {
      return await httpClient.post(
        'organization/task/annotation_image/',
        data,
        false,
        false,
        true,
        'Image Uploaded Successfully'
      );
    },

    playStop() {
      this.$refs.video.play();
    },

    handleLoadedMetaData() {},
    handlePlay() {},
  },
};
</script>
<style scoped>
video {
  width: 640px;
  height: 350px;
  /* height: 100%; */
}
</style>
