import getStepsList from './getStepsList';

export default function(firstLangDetails, secondLangDetails = null) {
  const defaultLangSteps = getStepsList(firstLangDetails)?.steps;
  var translationLangSteps = null;
  if (secondLangDetails) {
    translationLangSteps = getStepsList(secondLangDetails).steps;
  }

  const obj = {};

  defaultLangSteps?.forEach((step, i) => {
    obj[step.name] = translationLangSteps
      ? translationLangSteps[i].name
      : step.name;
    step.substeps?.forEach((substep, j) => {
      var translatedSubStep = translationLangSteps
        ? translationLangSteps[i].substeps[j]
        : substep;
      obj[substep] = translatedSubStep;
    });
  });
  return obj;
}
